import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { device } from "../lib/breakpoints";
import { APP_NAME, MISSION_STATEMENT } from "../helpers/constants";
import { Spacer } from "../styles/CommonElements";
import BookDemoButton from "../components/general/BookDemoButton";

const founderBeforeImage = "/images/mission/founder-before-2.svg";
const founderAfterImage = "/images/mission/founder-after-2.svg";
// const signatureImage = "/images/about/signature.svg"; // Using SVG for better quality

export default function OurMission() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <HeroSection>
        <Overlay />
        <HeroContent>
          <PageTitle>Our Mission</PageTitle>
          <Subtitle>A personal message from our CEO</Subtitle>
          {/*
          <Subtitle>
            A personal message from our CEO about how {APP_NAME} can transform your
            practice
          </Subtitle>
          */}
        </HeroContent>
      </HeroSection>

      <ContentSection>
        <MissionBox>
          {/* <QuoteMarkLeft>"</QuoteMarkLeft> */}
          <MissionText>
            At <CompanyName>{APP_NAME}</CompanyName>, {MISSION_STATEMENT}
          </MissionText>
          {/*<QuoteMarkRight>"</QuoteMarkRight> */}
        </MissionBox>

        <Spacer $marginTop="3rem" />

        <LetterWrapper>
          <LetterContainer>
            {/*
            <Salutation>Hello,</Salutation>
            */}

            <StoryGrid>
              <TextColumn>
                <Paragraph>It all started with a haircut.</Paragraph>

                <Paragraph>
                  When I came home from undergrad one weekend, my mother would
                  sometimes cut my hair. One day, she suddenly stopped mid-cut
                  and said, "
                  <i>
                    Bryan, I don't want to scare you, but you have a bald spot
                    on the top of your head.
                  </i>
                  " For years after that, I put off getting a hair transplant
                  even though I knew I needed one. I just couldn't get past the
                  uncertainty of how it would turn out, and I didn't love the
                  idea of all the needles and incisions.
                </Paragraph>

                <Paragraph>
                  As someone who works in visual tech, I kept thinking, "
                  <i>
                    I wish I could{" "}
                    <em>
                      <b>see</b>
                    </em>{" "}
                    what I'd look like before I do this.
                  </i>
                  " That idea became the foundation of what would become{" "}
                  <b>{APP_NAME}</b>.
                </Paragraph>

                <Paragraph>
                  When I built the first version, I tried it on myself. The
                  moment I saw my predicted results, it was like a lightbulb
                  went off. All that hesitation and anxiety finally disappeared.
                  I booked my procedure soon after, and the rest is history.
                </Paragraph>

                <Paragraph>
                  Once I saw how I could look, I went from{" "}
                  <Emphasis>
                    a hesitant lead to a committed patient in a single
                    consultation
                  </Emphasis>
                  .
                </Paragraph>
              </TextColumn>

              {/*
              <ImageColumn>
                <FounderImage src={founderImage} alt="Founder of BodyMagic" />
                <ImageCaption>
                  Me, from reluctant prospect to confident patient
                </ImageCaption>
              </ImageColumn>
              */}
            </StoryGrid>

            {/*
            <Spacer $marginTop="3rem" />
            */}

            {/* <StoryGrid reverse> */}
            <StoryGrid>
              <GalleryItem>
                <ImageContainer>
                  <ImageWrapper>
                    <ImageSection>
                      <ImageLabel>Initial</ImageLabel>
                      <MissionImage src={founderBeforeImage} alt={`Before`} />
                    </ImageSection>
                    <ImageSection>
                      <ImageLabel>Prediction</ImageLabel>
                      <MissionImage src={founderAfterImage} alt={`After`} />
                    </ImageSection>
                  </ImageWrapper>
                </ImageContainer>
                <ImageCaption>
                  My {APP_NAME} prediction before my hair transplant
                </ImageCaption>
              </GalleryItem>

              {/*
              <ImageColumn>
                <BeforeAfterImage
                  src={beforeAfterImage}
                  alt="Before and After Visualization"
                />
                <ImageCaption>
                  The visualization that converted me from prospect to patient
                </ImageCaption>
              </ImageColumn>
              */}
            </StoryGrid>

            {/*
            <Spacer $marginTop="1rem" />

            <MetricsContainer>
              <MetricBox>
                <MetricNumber>64%</MetricNumber>
                <MetricLabel>Higher Conversion Rate</MetricLabel>
              </MetricBox>
              <MetricBox>
                <MetricNumber>87%</MetricNumber>
                <MetricLabel>Patient Satisfaction</MetricLabel>
              </MetricBox>
              <MetricBox>
                <MetricNumber>41%</MetricNumber>
                <MetricLabel>Reduced Consultation Cycles</MetricLabel>
              </MetricBox>
            </MetricsContainer>
            */}

            <Paragraph>
              The biggest hurdle in converting consultations to procedures is{" "}
              <Emphasis>patient uncertainty.</Emphasis> I experienced this
              firsthand, because I couldn't get past the uncertainty of my own
              hair transplant. I created <b>{APP_NAME}</b> because I experienced
              the patient journey myself and saw the critical gap in the
              decision-making process.
            </Paragraph>

            <Paragraph>
              Your patients deserve to see what's possible before they commit,
              or even book a consultation, and your practice deserves the
              increased conversions that come from eliminating patient
              uncertainty.
            </Paragraph>

            <Paragraph>
              I invite you to explore how our products can transform your
              practice, streamline your consultation process, help your patients
              take the next step, and significantly boost your conversion rates.
            </Paragraph>

            <SignoffContainer>
              <SignoffText>Let's improve lives together,</SignoffText>
              <Spacer $marginTop="0.5rem" />
              <FounderName>Bryan Edelman</FounderName>
              <FounderTitle>Founder & CEO, {APP_NAME}</FounderTitle>
            </SignoffContainer>
          </LetterContainer>
        </LetterWrapper>

        <Spacer $marginTop="3.5rem" />

        {/*
        <TestimonialsSection>
          <TestimonialsTitle>What Surgeons Are Saying</TestimonialsTitle>
          <TestimonialsGrid>
            <TestimonialCard>
              <QuoteIcon>"</QuoteIcon>
              <TestimonialText>
                Since implementing {APP_NAME}, our consultation-to-procedure conversion rate has increased by 52%. Patients make decisions faster when they can visualize outcomes.
              </TestimonialText>
              <TestimonialAuthor>Dr. Sarah Johnson, Dermatologic Surgeon</TestimonialAuthor>
            </TestimonialCard>
            <TestimonialCard>
              <QuoteIcon>"</QuoteIcon>
              <TestimonialText>
                {APP_NAME} has become our secret weapon for consultations. Patients who were on the fence become immediately confident after seeing their potential results.
              </TestimonialText>
              <TestimonialAuthor>Dr. Michael Chen, Plastic Surgeon</TestimonialAuthor>
            </TestimonialCard>
          </TestimonialsGrid>
        </TestimonialsSection>
        */}

        <CTASection>
          <CTATitle>Transform Your Practice</CTATitle>
          <CTASubtitle>
            Join other cosmetic surgeons who are increasing conversions and
            patient satisfaction with <b>{APP_NAME}</b>
          </CTASubtitle>

          <CTAButtonsContainer>
            <BookDemoButton width="300px" size="medium" />
          </CTAButtonsContainer>

          <CTAFeatures>
            <FeatureItem>✓ Increase Consultation Conversions</FeatureItem>
            <FeatureItem>✓ Reduce Patient Uncertainty</FeatureItem>
            <FeatureItem>✓ Boost Practice Revenue</FeatureItem>
          </CTAFeatures>
        </CTASection>
      </ContentSection>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const CompanyName = styled.span`
  font-weight: 700;
  color: var(--color-brand-primary);
`;

const HeroSection = styled.div`
  position: relative;
  height: 400px;
  background-image: url("/images/mission/header.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    height: 300px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
`;

const PageTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin: 0;

  @media ${device.tablet} {
    font-size: 3.5rem;
  }

  @media ${device.mobile} {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-top: 1rem;

  @media ${device.mobile} {
    font-size: 1rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
`;

const ContentSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;

  @media ${device.mobile} {
    padding: 3rem 1.5rem;
  }
`;

const LetterWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: -10px;
    bottom: -10px;
    background-color: rgba(0, 0, 0, 0.03);
    z-index: -1;
    border-radius: 10px;
  }
`;

const LetterContainer = styled.div`
  background-color: white;
  padding: 3rem 3.5rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  background-image: linear-gradient(to bottom, #fcfcfc, white);
  border: 1px solid rgba(0, 0, 0, 0.05);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: linear-gradient(
      to right,
      var(--color-brand-primary-light),
      var(--color-brand-primary)
    );
    border-radius: 8px 8px 0 0;
  }

  @media ${device.mobile} {
    padding: 2rem 1.5rem;
  }
`;

/*
const Salutation = styled.p`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: var(--color-brand-primary);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
`;
*/

const StoryGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.reverse ? "1fr 1.5fr" : "1.5fr 1fr"};
  gap: 4rem;
  align-items: center;
  grid-template-columns: 1fr;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const TextColumn = styled.div`
  order: ${(props) => (props.reverse ? 2 : 1)};

  @media ${device.tablet}, ${device.mobile} {
    order: 2;
  }
`;

const ImageColumn = styled.div`
  order: ${(props) => (props.reverse ? 1 : 2)};
  width: 80%;
  margin: 10px auto;

  @media ${device.tablet}, ${device.mobile} {
    order: 1;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2.5rem;

  @media ${device.tablet}, ${device.mobile} {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
`;

const MetricBox = styled.div`
  background-color: #f9f9f9;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  width: 30%;
  border-left: 4px solid var(--color-brand-primary);

  @media ${device.tablet}, ${device.mobile} {
    width: 80%;
  }
`;

const MetricNumber = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-brand-primary);
  margin-bottom: 0.5rem;
`;

const MetricLabel = styled.div`
  font-size: 1rem;
  color: #555;
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #333;

  @media ${device.mobile} {
    font-size: 1.05rem;
    line-height: 1.7;
  }

  em {
    font-style: italic;
    font-weight: 500;
  }
`;

const FounderImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const GalleryItem = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
  transition: transform 0.3s ease;
  width: 80%;
  margin: 10px auto 20px auto;

  &:hover {
    transform: translateY(-5px);
  }

  @media ${device.tablet} {
    width: 90%;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const ImageSection = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 1;

  @media ${device.mobile} {
    bottom: 0;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    top: unset;
  }
`;

const BeforeAfterImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const ImageCaption = styled.p`
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
  color: #666;
`;

const SignoffContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -1.5rem;
    left: 0;
    width: 120px;
    height: 1px;
    background-color: #eaeaea;
  }
`;

const SignoffText = styled.p`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--color-brand-complimentary);
`;

const Signature = styled.img`
  height: 80px;
  margin: 0.5rem 0 1rem;
`;

const FounderName = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: var(--color-brand-primary);
`;

const FounderTitle = styled.p`
  font-size: 1rem;
  color: #666;
`;

const TestimonialsSection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);

  @media ${device.mobile} {
    padding: 2rem 1.5rem;
  }
`;

const TestimonialsTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-brand-primary);
  margin-bottom: 2rem;
  text-align: center;
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const TestimonialCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
  position: relative;
`;

const QuoteIcon = styled.div`
  font-size: 4rem;
  color: var(--color-brand-primary-light);
  opacity: 0.2;
  position: absolute;
  top: -10px;
  left: 10px;
  font-family: Georgia, serif;
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const TestimonialAuthor = styled.p`
  font-weight: 600;
  color: var(--color-brand-complimentary);
`;

const MissionBox = styled.div`
  background-color: #f8f9fa;
  padding: 3rem;
  border-radius: 12px;
  border: 3px solid var(--color-brand-primary);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  text-align: center;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top-width: 8px;

  @media ${device.mobile} {
    padding: 2rem 1.5rem;
  }
`;

const QuoteMarkLeft = styled.span`
  font-family: Georgia, serif;
  font-size: 6rem;
  position: absolute;
  top: 5px;
  left: 20px;
  color: var(--color-brand-primary-light);
  opacity: 0.2;
  line-height: 1;

  @media ${device.mobile} {
    font-size: 4rem;
    top: 10px;
    left: 10px;
  }
`;

const QuoteMarkRight = styled.span`
  font-family: Georgia, serif;
  font-size: 6rem;
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: var(--color-brand-primary-light);
  opacity: 0.2;
  line-height: 1;

  @media ${device.mobile} {
    font-size: 4rem;
    bottom: 10px;
    right: 10px;
  }
`;

const MissionTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-brand-primary);
  margin-bottom: 1.5rem;
`;

const MissionText = styled.p`
  font-size: 1.25rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding: 0 1.5rem;
  font-weight: 500;

  @media ${device.mobile} {
    font-size: 1.1rem;
    line-height: 1.7;
    padding: 0 0.5rem;
  }
`;

// CTA section components
const CTASection = styled.div`
  text-align: center;
  padding: 3.5rem;
  background: linear-gradient(
    135deg,
    var(--color-brand-primary-light),
    var(--color-brand-primary)
  );
  border-radius: 12px;
  color: black;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);

  @media ${device.mobile} {
    padding: 2rem 1.5rem;
  }
`;

const CTATitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media ${device.mobile} {
    font-size: 1.8rem;
  }
`;

const CTASubtitle = styled.p`
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto 2rem;
  line-height: 1.7;

  @media ${device.mobile} {
    font-size: 1.1rem;
  }
`;

const CTAButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 2rem;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
`;

const BookCallButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: white;
  color: var(--color-brand-primary);
  font-weight: 600;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CTAFeatures = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  margin-top: 1rem;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const FeatureItem = styled.div`
  font-size: 1.1rem;
  font-weight: 500;

  @media ${device.mobile} {
    font-size: 1rem;
  }
`;

// Additional styled component for emphasis
const Emphasis = styled.span`
  font-weight: 600;
  color: var(--color-brand-primary);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(var(--color-brand-primary-rgb), 0.2);
  }
`;

const ErrorPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.9rem;
`;

const MissionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f0f0f0;
`;
